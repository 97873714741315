import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import GetTenant from "./GetTenant";

//Calls api/callOut to send external calls to the API
//Method is either 'GET' or 'POST'
//////////Tenant is the first part of the url *IT IS NOW DYNAMIC* Ex: 'repere' --> repere.phpreaction.com
//Ressource is the ressource you wish to access Ex: 'open-api/v3/product' --> repere.phpreaction.com/open-api/v3/product
//Parameters is a list of query params Ex: {sku:123456789} --> repere.phpreaction.com/open-api/v3/product?sku=123456789
//Payload is the body of the request, it is only used on post
export default async function CallAPI(
  method: string,
  ressource: string,
  parameters?: any,
  payload?: any
) {
  const instance = Axios.create();
  const axios = setupCache(instance, { storage: undefined });
  const tenant = GetTenant();

  var response;

  try {
    if (method == "POST") {
      response = await axios.post("/api/callOut", payload, {
        cache: false,
        params: { tenant, ressource, parameters },
      });
    } else if (method == "PUT") {
      response = await axios.put("/api/callOut", payload, {
        cache: false,
        params: { tenant, ressource, parameters },
      });
    } else {
      response = await axios.get("/api/callOut", {
        params: { tenant, ressource, parameters },
        cache: {
          ttl: 60 * 60 * 1000,
        },
      });
    }
    console.log(method + " Calling API: ", tenant, ressource, parameters, payload, "\nresponse : ", response.data);
    return response;
  } catch (err: any) {
    throw new Error(err);
  }
}

export const CallAPIURL = {
  status: "open-api/v3/status",
  getStatusDropdown: "open-api/v3/project_statuses/dropdown/get",
  getTasks: "open-api/v3/project_tasks",
  getTasksManager: "open-api/v3/project_tasks/task_manager/listing",
  getTasksDropdown: "open-api/v3/project_tasks/dropdown/get",
  getEmpDropdown: "open-api/v3/person_employees/dropdown/get",
  getProjectsDropdown: "open-api/v3/projects/dropdown/get",
  getProjectSectorsDropdown: "open-api/v3/project_sectors/dropdown/get",
  getProjectPrioritiesDropdown: "open-api/v3/project_priorities/dropdown/get",
  getTimesheetsDropdown: "open-api/v3/timesheets/dropdown/get",
  getTimesheets: "open-api/v3/timesheets",
  getProject: "open-api/v3/projects",
  getKVS: "open-api/v3/users/logged_user_kvs/get",
  getUserRoles: "open-api/v3/users/logged_user_roles/get",
  getLoggedUserInfos: "open-api/v3/users/logged_user_informations/get",
};
